<template>
  <div>
    <b-card title="Bienvenidos al panel de control.">
      <b-card-text>La Universidad Nacional Intercultural de la Amazonía a través de la Dirección de Bienestar Universitario y Deporte está desarrollando y sistematizando su Proyecto “Mejoras en la Calidad de Vida Salud Física y Mental” aplicable a toda la Comunidad Universitaria, así como nuevas bases de datos computarizadas para dar operatividad a los servicios de apoyo Académico y social brindados, organizados y dirigidos por la Dirección de Bienestar Universitario en coordinación con cada Facultad..</b-card-text>
      <!-- <b-card-text>Please make sure to read our <b-link
        href="#/"
        target="_blank"
      >
        Template Documentation
      </b-link> to understand where to go from here and how to use our template.</b-card-text> -->
      <div class="mb-2 text-center">
        <b-button
          v-if="esRolEstudiante()"
          variant="outline-primary"
          class="px-3"
          :href="manualUsuarioEstudiante"
          target="_blank"
        >
          <feather-icon icon="BookOpenIcon" />
          Manual de usuario
        </b-button>

        <b-button
          v-if="!esRolEstudiante()"
          variant="outline-primary"
          class="px-3"
          :href="manualUsuarioAdministrativo"
          target="_blank"
        >
          <feather-icon icon="BookOpenIcon" />
          Manual de usuario
        </b-button>
      </div>
      <div class="text-center">
        <b-img
          fluid
          :src="imagenFondoLogin"
          width="800"
          style="opacity: 0.8;"
        />
      </div>

    </b-card>

    <!-- <b-card title="Want to integrate JWT? 🔒">
      <b-card-text>We carefully crafted JWT flow so you can implement JWT with ease and with minimum efforts.</b-card-text>
      <b-card-text>Please read our  JWT Documentation to get more out of JWT authentication.</b-card-text>
    </b-card> -->
  </div>
</template>

<script>
import {
  BCard, BCardText, BButton, BImg,
} from 'bootstrap-vue'
import { mapState } from 'vuex'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BImg,
  },
  data() {
    return {
      roles: [],
    }
  },
  computed: {
    ...mapState('ajustesApp', ['manualUsuarioEstudiante', 'manualUsuarioAdministrativo', 'imagenFondoLogin']),
  },
  async created() {
    await this.$http.get('/usuario_datos')
      .then(response => {
        // this.userData = response.data.usuario
        this.roles = response.data.roles
      })
  },

  methods: {
    esRolEstudiante() {
      if (this.roles.some(val => val.name === 'Estudiante')) {
        return true
      }
      return false
    },
  },
}
</script>

<style>

</style>
